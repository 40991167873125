export const trigger = (eventName, el, data = {}) => {
  let event
  if (typeof eventName === 'object') {
    data = eventName.data
    eventName = eventName.event
  }
  if (typeof window.CustomEvent === 'function') {
    event = new CustomEvent(eventName, { detail: data })
  } else {
    event = document.createEvent('CustomEvent')
    event.initCustomEvent(eventName, true, true, data)
  }
  el.dispatchEvent(event)
  return el
}

export const decode = (str) => decodeURIComponent(str).replace(/\+/g, ' ')

const handleize = str => str ? str.replace(/[ /_]/g, '-').toLowerCase() : ''

export function mapResultItemToDataLayerViewItemList (product, productListName) {
  const color = product.itemVariant || ''
  var productData = {
    item_id: product.variants[0].sku,
    item_name: product.title.replace("'", ''),
    currency: window.BARREL && window.BARREL.currencyCode ? window.BARREL.currencyCode : 'USD',
    item_brand: product.vendor,
    item_list_id: handleize(productListName),
    item_list_name: productListName,
    item_variant: color,
    price: product.price,
    quantity: 1
  }
  if (product.allCategories && product.allCategories.length) {
    productData['item_category'] = product.allCategories.toString()
  }
  return productData
}

export function splitArrayIntoChunksOfLen (arr, len) {
  const chunks = []

  for (let i = 0; i < arr.length; i += len) {
    const newArr = arr.slice(i, i + len).map(function(product, index) {
      product.index = index
      return product
    })
    chunks.push(newArr)
  }

  return chunks
}

export function pushViewItemListToDataLayer (viewItemList) {
  if (!viewItemList || !viewItemList.length) return
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: "view_item_list",
    ecommerce: {
      items: viewItemList
    }
  });
}

export function splitAndPushViewItemListToDataLayer (viewItemList) {
  const impressionChunks = splitArrayIntoChunksOfLen(viewItemList, 10)
  impressionChunks.forEach((chunk) => {
    pushViewItemListToDataLayer(chunk)
  })
}
